import { css } from "@emotion/react";
import Head from "next/head";
import Image from "next/image";
import { Row, Col, Button, Menu, Carousel, Grid, Dropdown } from "antd";
import Link from "next/link";
import { MenuOutlined } from "@ant-design/icons";
import { GetStaticProps } from "next";
import { useAnalytics } from "use-analytics";
import { useRouter } from "next/router";
import { PrismicDocument } from "@prismicio/types";
import { RichText, RichTextBlock } from "prismic-reactjs";
import { getKonsolHomeDoc } from "lib/prismic";
import EnquirySendForm from "components/Forms/EnquirySendForm";
import { colourCss, typography } from "config/theme";
import { LogoBusiness } from "assets/vectors/logo";
import Footer from "layouts/Footer";

const styles = {
    headerContainer: css`
        position: fixed;
        top: 0;
        width: 100%;
        left: 0;
        z-index: 9;
        position: fixed;
        background: #fff;
        padding: 0 24px;
        box-shadow: 0 0 4px rgba(51, 51, 51, 0.15);
    `,
    headerMaxWidth: css`
        max-width: 1170px;
        margin: auto;
    `,
    headerRow: css`
        height: 80px;
    `,
    content: css`
        padding-top: 80px;
    `,
    sectionContainer: css`
        padding: 64px 24px;
    `,
    sectionMaxWidth: css`
        max-width: 1170px;
        margin: auto;
    `,
    sectionHeader: css`
        text-align: center;
        margin-bottom: 40px;
    `,
    sectionHeaderTitle: css`
        font-family: Lato;
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        line-height: 29px;
    `,
    sectionSubtitle: css`
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 140%;
        /* or 25px */

        /* Brand Primary */
        color: #232a92;
    `,
    bannerContainer: css`
        display: flex;
        align-items: stretch;
        justify-content: center;
        position: relative;
        overflow: hidden;
        height: 40vw;
        cursor: pointer;
        @media (max-width: 700px) {
            height: 60vw;
        }
    `,
    bannerImageContainer: css`
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    `,
    bannerImage: css`
        object-fit: cover;
        object-position: 50% 50%;
        background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    `,
    bannerImageOverlay: css`
        width: 100%;
        position: absolute;
        zindex: 1;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    `,
    bannerContent: css`
        width: 100%;
        max-width: 1170px;
        position: relative;
        align-items: center;

        justify-content: flex-start;

        padding: 32px;
        z-index: 2;

        margin: auto;
        color: #ffffff;
    `,
    bannerTitle: css`
        font-family: Lato;
        font-style: normal;
        font-weight: 900;
        font-size: 40px;
        line-height: 48px;

        /* White */
        color: #ffffff;
    `,
    bannerSubtitle: css`
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 140%;
    `,
    bannerBody: css`
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 140%;
        /* or 25px */

        /* White */
        color: #ffffff;
    `,
    bannerTick: css`
        font-size: 20px;
        color: #ffffff;
    `,
    enquirySectionRoot: css`
        text-align: left;
    `,
    enquiryFormContainer: css`
        max-width: 700px;
        margin: auto;
    `,
    featureTitle: css`
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
    `,
    featureContent: css`
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: #16056b;
    `,
    mockupImage: css`
        width: 100%;
    `,
    benefitTileContainer: css`
        text-align: center;
    `,
    benefitTileIconContainer: css`
        margin-bottom: 24px;
    `,
    benefitTileIcon: css`
        width: 40%;
    `,
    benefitTileTitleContainer: css`
        margin-bottom: 16px;
    `,
    benefitTileTitle: css`
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
    `,
    benefitTileContent: css`
        font-family: Lato;
        font-size: 22px;
    `,
    backgroundGrey: css`
        background: rgba(229, 229, 229, 0.3);
    `,
    backgroundWhite: css`
        background: #ffffff;
    `,
    textUnderline: css`
        text-decoration: underline;
    `,
    menuButton: css`
        padding: 0 16px;
        &:hover {
            cursor: pointer;
        }
    `,
};

type HomePage = {
    pageDoc: PrismicDocument;
};

type PrismicImage = {
    alt?: string;
    copyright?: string;
    dimensions: { width: number; height: number };
    url: string;
};

const HomePage = ({ pageDoc }: HomePage): JSX.Element => {
    return (
        <div>
            <Head>
                <title>Home - Courtsite for Business</title>
            </Head>
            <Header />
            <div css={styles.content}>
                <SectionCover />
                <SectionFeatures />
                <SectionBenefit benefits={pageDoc.data.benefit} />
                <SectionEnquiry />
            </div>
            <Footer />
        </div>
    );
};

const Header = (): JSX.Element => {
    const screens = Grid.useBreakpoint();
    const isXs = !!screens.xs;
    const handleFeaturesClick = (): void => {
        scrollToTargetAdjusted("feature", 80);
    };
    const handleWhyCourtsiteClick = (): void => {
        scrollToTargetAdjusted("why-courtsite", 80);
    };
    const handleContactUsClick = (): void => {
        scrollToTargetAdjusted("business-enquiry", 80);
    };

    return (
        <div css={styles.headerContainer}>
            <div css={styles.headerMaxWidth}>
                <Row
                    justify="space-between"
                    align="middle"
                    css={styles.headerRow}
                >
                    <Col span={4}>
                        <Link href="/">
                            <a>
                                <LogoBusiness
                                    style={{
                                        height: "36px",
                                    }}
                                    alt="Courtsite Business Home"
                                />
                            </a>
                        </Link>
                    </Col>
                    <Col xs={0} lg={16}>
                        <Link href="/pricing" passHref>
                            <Button type="link" size="large">
                                Pricing
                            </Button>
                        </Link>
                    </Col>
                    <Col span={4}>
                        <div css={{ display: "flex", alignItems: "center" }}>
                            {!isXs && (
                                <div css={{ padding: "0px 32px" }}>
                                    <Link href="/login">
                                        <a>
                                            <Button type="link" size="large">
                                                <span
                                                    css={styles.textUnderline}
                                                >
                                                    Business Login
                                                </span>
                                            </Button>
                                        </a>
                                    </Link>
                                </div>
                            )}
                            <Dropdown
                                trigger={["click"]}
                                placement="bottomRight"
                                overlay={
                                    <Menu>
                                        {isXs && (
                                            <Menu.Item>
                                                <Link href="/login">
                                                    Business Login
                                                </Link>
                                            </Menu.Item>
                                        )}
                                        <Menu.Item
                                            onClick={handleFeaturesClick}
                                        >
                                            Features of our Courtsite Software
                                        </Menu.Item>
                                        <Menu.Item
                                            onClick={handleWhyCourtsiteClick}
                                        >
                                            Why Courtsite for Business
                                        </Menu.Item>
                                        <Menu.Item
                                            onClick={handleContactUsClick}
                                        >
                                            Contact Us
                                        </Menu.Item>
                                        {isXs && (
                                            <Menu.Item>
                                                <Link href="/pricing" passHref>
                                                    Pricing
                                                </Link>
                                            </Menu.Item>
                                        )}
                                    </Menu>
                                }
                            >
                                <div css={styles.menuButton}>
                                    <MenuOutlined style={{ fontSize: 20 }} />
                                </div>
                            </Dropdown>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

const SectionHeader = ({
    title,
    subtitle,
}: {
    title?: string;
    subtitle?: string;
}): JSX.Element => {
    return (
        <div css={styles.sectionHeader}>
            {title && (
                <Row justify="center" gutter={[0, 8]}>
                    <Col>
                        <span
                            css={[colourCss.primary, styles.sectionHeaderTitle]}
                        >
                            {title}
                        </span>
                    </Col>
                </Row>
            )}
            {subtitle && (
                <Row justify="center">
                    <Col>
                        <span css={[styles.sectionSubtitle, colourCss.primary]}>
                            {subtitle}
                        </span>
                    </Col>
                </Row>
            )}
        </div>
    );
};

const bannerImages: {
    key: string;
    desktopImage: string;
    mobileImage?: string;
    caption?: string;
    alt: string;
    href?: string;
}[] = [
    {
        key: "pricing-package-plans",
        desktopImage: "/images/pricing_plans_v2.png",
        alt: "Pick the right plan for your business",
        href: "/pricing",
    },
    {
        key: "custom-pricing",
        desktopImage: "/images/custom_pricing.svg",
        alt: "Custom pricing options for your customers",
        href: "https://blog.courtsite.my/new-feature-more-pricing-options-for-your-customers/",
    },
    {
        key: "cover",
        desktopImage: "/images/cover.jpg",
        alt: "Cover",
    },
];

const SectionCover = (): JSX.Element => {
    const screens = Grid.useBreakpoint();
    const isDesktop = !!screens.lg;
    const router = useRouter();
    const { track } = useAnalytics();

    const handleImageClick = (href?: string) => {
        return () => {
            if (href) {
                track("Click Konsol Home Banner", {
                    target: href,
                });
                router.push(href);
            }
        };
    };

    return (
        <Carousel autoplay>
            {bannerImages.map((banner) => {
                return (
                    <div css={styles.bannerContainer} key={banner.key}>
                        <div css={styles.bannerImageContainer}>
                            <Image
                                src={
                                    isDesktop
                                        ? banner.desktopImage
                                        : banner.mobileImage ||
                                          banner.desktopImage
                                }
                                css={[
                                    styles.bannerImage,
                                    banner.href && {
                                        cursor: "pointer",
                                    },
                                ]}
                                sizes="(max-width: 400px) 300px, (max-width: 700px) 500px"
                                unoptimized
                                layout="fill"
                                alt={banner.alt}
                                onClick={handleImageClick(banner.href)}
                            />
                        </div>
                    </div>
                );
            })}
        </Carousel>
    );
};

const SectionFeatures = (): JSX.Element => {
    return (
        <div
            css={[styles.sectionContainer, styles.backgroundGrey]}
            id="feature"
        >
            <SectionHeader
                title="What is Courtsite for Business?"
                subtitle="Features of our Courtsite Software"
            />
            <div css={styles.sectionMaxWidth}>
                <Row gutter={[32, 48]} align="middle">
                    <Col lg={12}>
                        <Row gutter={[0, 24]}>
                            <Col>
                                <span
                                    css={[
                                        styles.featureTitle,
                                        colourCss.secondaryBright,
                                    ]}
                                >
                                    Courtsite Console
                                </span>
                            </Col>
                        </Row>
                        <Row gutter={[0, 16]}>
                            <Col xs={2}>
                                <img
                                    src="/images/checkBlue.svg"
                                    css={styles.bannerTick}
                                    alt="tick"
                                />
                            </Col>
                            <Col xs={22}>
                                <span
                                    css={[
                                        styles.featureContent,
                                        colourCss.textPrimary,
                                    ]}
                                >
                                    Configurable facility management software
                                    with flexible schedular, pricing and booking
                                    modules.
                                </span>
                            </Col>
                        </Row>
                        <Row gutter={[0, 16]}>
                            <Col xs={2}>
                                <img
                                    src="/images/checkBlue.svg"
                                    css={styles.bannerTick}
                                    alt="tick"
                                />
                            </Col>
                            <Col xs={22}>
                                <span
                                    css={[
                                        styles.featureContent,
                                        colourCss.textPrimary,
                                    ]}
                                >
                                    Cloud-based technology and secure data
                                    storage.
                                </span>
                            </Col>
                        </Row>
                        <Row gutter={[0, 32]}>
                            <Col xs={2}>
                                <img
                                    src="/images/checkBlue.svg"
                                    css={styles.bannerTick}
                                    alt="tick"
                                />
                            </Col>
                            <Col xs={22}>
                                <span
                                    css={[
                                        styles.featureContent,
                                        colourCss.textPrimary,
                                    ]}
                                >
                                    Tailored business data analytics and
                                    reports.
                                </span>
                            </Col>
                        </Row>
                        <Row gutter={[0, 24]}>
                            <Col>
                                <span
                                    css={[
                                        styles.featureTitle,
                                        colourCss.secondaryBright,
                                    ]}
                                >
                                    Courtsite Lighting Automation System
                                </span>
                            </Col>
                        </Row>
                        <Row gutter={[0, 16]}>
                            <Col xs={2}>
                                <img
                                    src="/images/checkBlue.svg"
                                    css={styles.bannerTick}
                                    alt="tick"
                                />
                            </Col>
                            <Col xs={22}>
                                <span
                                    css={[
                                        styles.featureContent,
                                        colourCss.textPrimary,
                                    ]}
                                >
                                    Integrated bookings and lightings modules
                                    that turn lightings on and off automatically
                                    according to bookings.
                                </span>
                            </Col>
                        </Row>
                        <Row gutter={[0, 16]}>
                            <Col xs={2}>
                                <img
                                    src="/images/checkBlue.svg"
                                    css={styles.bannerTick}
                                    alt="tick"
                                />
                            </Col>
                            <Col xs={22}>
                                <span
                                    css={[
                                        styles.featureContent,
                                        colourCss.textPrimary,
                                    ]}
                                >
                                    Remote lightings control system.
                                </span>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={12}>
                        <img
                            src="/images/mockup/desktop.jpg"
                            css={styles.mockupImage}
                            alt="desktop"
                        />
                    </Col>
                </Row>
                <Row align="middle" gutter={[32, 16]}>
                    <Col lg={12}>
                        <img
                            src="/images/mockup/mobile.jpeg"
                            css={styles.mockupImage}
                            alt="mobile"
                        />
                    </Col>
                    <Col lg={12}>
                        <Row gutter={[0, 24]}>
                            <Col>
                                <span
                                    css={[
                                        styles.featureTitle,
                                        colourCss.secondaryBright,
                                    ]}
                                >
                                    Courtsite Platform
                                </span>
                            </Col>
                        </Row>
                        <Row gutter={[0, 16]}>
                            <Col xs={2}>
                                <img
                                    src="/images/checkBlue.svg"
                                    css={styles.bannerTick}
                                    alt="tick"
                                />
                            </Col>
                            <Col xs={22}>
                                <span
                                    css={[
                                        styles.featureContent,
                                        colourCss.textPrimary,
                                    ]}
                                >
                                    List your sports facility to receive online
                                    bookings and payments from your customers
                                    24/7 wherever they are.
                                </span>
                            </Col>
                        </Row>
                        <Row gutter={[0, 32]}>
                            <Col xs={2}>
                                <img
                                    src="/images/checkBlue.svg"
                                    css={styles.bannerTick}
                                    alt="tick"
                                />
                            </Col>
                            <Col xs={22}>
                                <span
                                    css={[
                                        styles.featureContent,
                                        colourCss.textPrimary,
                                    ]}
                                >
                                    Real-time updates on resource availability
                                    with synchronised online and offline
                                    bookings.
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ offset: 2 }}>
                                <a
                                    href="https://www.courtsite.my"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Button type="primary" size="large">
                                        Explore Courtsite Platform
                                    </Button>
                                </a>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

const SectionBenefit = ({
    benefits,
}: {
    benefits: {
        title: RichTextBlock[];
        description: RichTextBlock[];
        image: PrismicImage;
    }[];
}): JSX.Element => {
    return (
        <div
            id="why-courtsite"
            css={[styles.sectionContainer, styles.backgroundWhite]}
        >
            <div css={styles.sectionMaxWidth}>
                <SectionHeader
                    title="Why Courtsite for Business?"
                    subtitle="Let us tell you why"
                />
                <Row gutter={[40, 48]}>
                    {benefits.map((b, index) => (
                        <Col
                            sm={24}
                            md={12}
                            lg={8}
                            css={styles.benefitTileContainer}
                            key={index}
                        >
                            <div css={styles.benefitTileIconContainer}>
                                <img
                                    src={b.image.url}
                                    css={styles.benefitTileIcon}
                                    alt={b.image.alt}
                                />
                            </div>
                            <div css={styles.benefitTileTitleContainer}>
                                <span
                                    css={[
                                        styles.benefitTileTitle,
                                        colourCss.secondaryBright,
                                    ]}
                                >
                                    {RichText.asText(b.title)}
                                </span>
                            </div>
                            <div>
                                <span
                                    css={[
                                        typography.mainContent,
                                        colourCss.textPrimary,
                                    ]}
                                >
                                    <RichText render={b.description} />
                                </span>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
        </div>
    );
};

const SectionEnquiry = (): JSX.Element => {
    return (
        <div
            css={[styles.sectionContainer, styles.backgroundGrey]}
            id="business-enquiry"
        >
            <div css={styles.enquirySectionRoot}>
                <SectionHeader
                    title="Interested to find out more?"
                    subtitle="Leave us a message and we will get in touch with you
                        shortly."
                />
                <div css={styles.enquiryFormContainer}>
                    <EnquirySendForm />
                </div>
            </div>
        </div>
    );
};

export const getStaticProps: GetStaticProps<HomePage> = async () => {
    const doc = await getKonsolHomeDoc();

    return {
        props: {
            pageDoc: doc,
        },
    };
};
const scrollToTargetAdjusted = (id: string, offset: number): void => {
    const element = document.getElementById(id);

    if (element) {
        const elementPosition =
            element.getBoundingClientRect().top +
            document.documentElement.scrollTop;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
        });
    }
};

export default HomePage;
